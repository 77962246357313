@import "module";

#__next {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  margin: auto;
  min-height: 100vh;

  & > main {
    // padding-top: 160px;
    color: #0F0E11;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

h1,
.h1 {
  margin-bottom: $spacer * 1.5;
  font-family: 'Proxima Nova Light', sans-serif;
  font-weight: var(#{--bs-font-weight-h1});
}

h2,
.h2 {
  margin-bottom: $spacer * 1.5;
  font-family: 'Proxima Nova Light', sans-serif;
  font-weight: var(#{--bs-font-weight-h2});
}

h3,
.h3 {
  margin-bottom: $spacer * 1.5;
  font-family: 'Proxima Nova Light', sans-serif;
  font-weight: var(#{--bs-font-weight-h3});
}

h4,
.h4 {
  font-family: 'Proxima Nova Light', sans-serif;
  font-weight: var(#{--bs-font-weight-h4});
}

h5,
.h5 {
  font-family: 'Proxima Nova Light', sans-serif;
  font-weight: var(#{--bs-font-weight-h5});
}

h6,
.h6 {
  font-family: 'Proxima Nova Light', sans-serif;
  font-weight: var(#{--bs-font-weight-h6});
}

.mw {
  &-sm {
    max-width: map-get($container-max-widths, "sm");
  }

  &-md {
    max-width: map-get($container-max-widths, "md");
  }

  &-lg {
    max-width: map-get($container-max-widths, "lg");
  }

  &-xl {
    max-width: map-get($container-max-widths, "xl");
  }

  &-xxl {
    max-width: map-get($container-max-widths, "xxl");
  }
}

.text {
  &-white {
    a {
      color: $white;
    }
    svg {
      fill: $white;
    }
  }
  &-dark {
    svg {
      fill: $dark;
    }
  }
}

details {
  summary {
    font-weight: $font-weight-bold;
    text-transform: capitalize;
  }
}

b,
strong {
  font-weight: $font-weight-semibold;
}

hr {
  opacity: 1;
  border-color: var(#{--bs-primary})
}

[data-sourceflow-path]:not(img):empty {
  display: none;
}

.sr-only {
  display: none;
}

.btn {
  --bs-btn-font-family: var(#{--bs-font-family-button});
  font-size: 12px;
  padding-left: 0;
  padding-right: 0;

  &-block {
    width: 100%;
  }

  &-outline {
    &-primary {
      --bs-btn-color: var(#{--bs-primary}) !important;
      --bs-btn-border-color: var(#{--bs-primary}) !important;
      --bs-btn-hover-bg: var(#{--bs-primary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-primary}) !important;
      --bs-btn-active-bg: var(#{--bs-primary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-primary-active}) !important;

      svg {
        transition: $transition-base;
        stroke: var(#{--bs-primary});
      }

      &:hover {
        svg {
          stroke: #fff;
        }
      }
    }

    &-secondary {
      --bs-btn-color: var(#{--bs-secondary}) !important;
      --bs-btn-border-color: var(#{--bs-secondary}) !important;
      --bs-btn-hover-bg: var(#{--bs-secondary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-secondary}) !important;
      --bs-btn-active-bg: var(#{--bs-secondary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-secondary-active}) !important;
      color: var(#{--bs-dark}) !important;

      svg {
        stroke: var(#{--bs-dark}) !important;
        fill: var(#{--bs-dark}) !important;
        transition: $transition-base;
      }
    }

    &-tertiary {
      --bs-btn-color: var(#{--bs-tertiary}) !important;
      --bs-btn-border-color: var(#{--bs-tertiary}) !important;
      --bs-btn-hover-bg: var(#{--bs-tertiary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-tertiary}) !important;
      --bs-btn-active-bg: var(#{--bs-tertiary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-tertiary-active}) !important;
      color: var(#{--bs-dark}) !important;

      svg {
        stroke: var(#{--bs-dark}) !important;
        fill: var(#{--bs-dark}) !important;
        transition: $transition-base;
      }
    }

    &-quaternary {
      --bs-btn-color: var(#{--bs-quaternary}) !important;
      --bs-btn-border-color: var(#{--bs-quaternary}) !important;
      --bs-btn-hover-bg: var(#{--bs-quaternary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-quaternary}) !important;
      --bs-btn-active-bg: var(#{--bs-quaternary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-quaternary-active}) !important;
      color: var(#{--bs-dark}) !important;

      svg {
        stroke: var(#{--bs-dark}) !important;
        fill: var(#{--bs-dark}) !important;
        transition: $transition-base;
      }
    }
  }

  &-primary {
    --bs-btn-bg: var(#{--bs-primary}) !important;
    --bs-btn-border-color: var(#{--bs-primary}) !important;
    --bs-btn-hover-bg: var(#{--bs-primary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-primary-active}) !important;
    --bs-btn-active-bg: var(#{--bs-primary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-primary-active}) !important;
  }

  &-secondary {
    --bs-btn-bg: var(#{--bs-secondary}) !important;
    --bs-btn-border-color: var(#{--bs-secondary}) !important;
    --bs-btn-hover-bg: var(#{--bs-secondary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-secondary-active}) !important;
    --bs-btn-active-bg: var(#{--bs-secondary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-secondary-active}) !important;
    color: var(#{--bs-dark}) !important;

    svg {
      stroke: var(#{--bs-dark}) !important;
      fill: var(#{--bs-dark}) !important;
    }
  }

  &-tertiary {
    --bs-btn-bg: var(#{--bs-tertiary}) !important;
    --bs-btn-border-color: var(#{--bs-tertiary}) !important;
    --bs-btn-hover-bg: var(#{--bs-tertiary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-tertiary-active}) !important;
    --bs-btn-active-bg: var(#{--bs-tertiary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-tertiary-active}) !important;
  }

  &-quaternary {
    --bs-btn-bg: var(#{--bs-quaternary}) !important;
    --bs-btn-border-color: var(#{--bs-quaternary}) !important;
    --bs-btn-hover-bg: var(#{--bs-quaternary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-quaternary-active}) !important;
    --bs-btn-active-bg: var(#{--bs-quaternary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-quaternary-active}) !important;
    color: black;

    svg {
      fill: black;
    }
  }
}

.hover-bg {
  &-primary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-primary}) !important;
      color: white;
    }
  }
  &-secondary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-secondary}) !important;
    }
  }
  &-tertiary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-tertiary}) !important;
    }
  }
  &-quaternary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-quaternary}) !important;
    }
  }
}

.form {
  &-label {
    font-weight: $font-weight-semibold;
  }

  &-control {
    border-radius: var(#{--bs-border-radius-sm});
  }

  &-check-input {
    &:checked {
      background-color: var(#{--bs-primary});
      border-color: var(#{--bs-primary});
    }
  }
}

.card {
  --bs-card-border-radius: var(#{--bs-border-radius-sm});
  --bs-card-inner-border-radius: 0;
  border: 0;
  overflow: hidden;

  &-header {
    border-bottom: 0;
    font-weight: $font-weight-semibold;
    padding: $spacer $spacer * 2;
    background-color: var(#{--bs-primary});
    color: $white;

    & > * {
      font-size: inherit;
      font-weight: inherit;
      margin: 0;
    }
  }

  &-body {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-top: 0;
    border-bottom-left-radius: var(#{--bs-border-radius-sm});
    border-bottom-right-radius: var(#{--bs-border-radius-sm});
    padding: $spacer $spacer $spacer * 2 $spacer;

    @include media-breakpoint-up(md) {
      padding: $spacer * 1.5;
    }
  }
}

.carousel {
  &-item {
    background-color: transparent;
  }
}

.span-padding {
  padding-right: 10px;
}

.span_highlight, .highlight {
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    height: 28px;
    max-height: 50%;
    width: 100%;
    bottom: 4px;
    left: 0;
    z-index: -1;
  }

  &.primary::before {
    background-color: var(#{--bs-primary});
  }

  &.secondary::before {
    background-color: var(#{--bs-secondary});
  }

  &.tertiary::before {
    background-color: var(#{--bs-tertiary});
  }

  &.quaternary::before {
    background-color: var(#{--bs-quaternary});
  }

  &.neutral1::before {
    background-color: #A595BE;
  }

  &.neutral2::before {
    background-color: #30BFA7;
  }
}

.show_mobile {
  margin-top: -60px;
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.hero-section {
  padding: 21px 0 10vh 8vw;

  @media (max-width: 991.98px) {
    padding: 21px 0 10vh 5vw;
  }
}



.btn-primary {
  background-color: var(#{--bs-primary});
  color: white;
  transition: all 300ms ease-in;

  svg {
    fill: white;
  }

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    transition: all 300ms ease-in;
  }
}

.btn-secondary {
  background-color: var(#{--bs-secondary});
  transition: all 300ms ease-in;

  svg {
    fill: white;
  }

  &:hover {
    background-color: var(#{--bs-secondary-active});
    transition: all 300ms ease-in;
  }
}

.btn-tertiary {
  background-color: var(#{--bs-tertiary});
  color: white;
  transition: all 300ms ease-in;

  svg {
    fill: white;
  }

  &:hover {
    background-color: var(#{--bs-tertiary-active});
    transition: all 300ms ease-in;
  }
}

.btn-quaternary {
  background-color: var(#{--bs-quaternary});
  transition: all 300ms ease-in;

  svg {
    fill: black;
  }

  &:hover {
    background-color: var(#{--bs-quaternary-active});
    transition: all 300ms ease-in;
  }
}

.btn-neutral2 {
  background-color: #30BFA7;
  color: white;
}

.size-h2 {
  font-size: 40px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

.font-regular {
  font-family: 'Proxima Nova', sans-serif;
}

//cookies popup
#freeprivacypolicy-com---nb {
  bottom: 15px !important;
  right: 15px !important;
}

.cc-nb-main-container {
  display: flex !important;
  padding: 15px !important;
  font-size: 13px !important;
  width: 600px;
  border: 1px solid black;
  padding-bottom: 0 !important;
  
  .cc-nb-title {
    display: none;
  }

  .cc-nb-text {
    font-size: 13px !important;
    font-weight: 600 !important;
  }

  .cc-nb-okagree {
    border-radius: 5px;
    width: 65px !important;
    padding: 5px 10px !important;
    font-size: 13px !important;
    background-color: var(#{--bs-primary}) !important;
  }

  .cc-nb-changep {
    display: none;
  }
}

//navbar
.navbar-nav {
  text-transform: uppercase;
}

@media (max-width: 575.98px) {
  .highlight {
    &.primary::before, &.secondary::before, &.tertiary::before, &.quaternary::before, &.neutral1::before, &.neutral2::before {
      background-color: transparent;
    }
  }

  .show_mobile {
    display: inline;
    height: 0;
    line-height: 0;
  }

  h2, .h2 {
    font-size: 32px;
  }
}