@import "src/scss/module";

.header {
  padding: 0;
  overflow-y: hidden;
  max-height: 100vh;
  transition: all 300ms ease-in;
  font-weight: 600;

  &.announcement {
    padding-top: 30px;
  }

  @include media-breakpoint-up(md) {
    overflow-y: initial;
  }

  &__brand {
    padding: $spacer * 0.25;

    img {
      height: 72px;
      object-fit: contain;
      margin-right: 5vw;
    }

    @include media-breakpoint-up(sm) {
      padding: 0 !important;

      img {
        height: 72px;
      }
    }
  }

  .navItem {

    .navLink {
      position: relative;
      font-size: 14px;
      color: white;

      &:focus,
      &:hover {
        &::after {
          content: "";
          width: 80%;
          position: absolute;
          bottom: 14px;
          left: 10%;
          border-bottom: solid 2px var(#{--bs-primary});
        }
      }
    }
  }

  .navButtonList {
    display: flex;
    gap: 20px;

    .navButton {
      width: 140px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .navButtonIcon {
        margin: 0 4px;
      }
    }

    .lightHover:hover {
      opacity: 0.8;
    }

    .darkText:hover {
      color: black;
    }

    .darkHover:hover {
      background-color: #5C425B;
    }
    
  }

  :global {
    .navbar {
      &-toggler {
        background-color: rgba(var(#{--bs-emphasis-color-rgb}), 0.4);
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 1.5em;
          height: 1.5em;

          span {
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            height: 3px;
            width: 100%;
            background-color: white;
          }
        }
      }

      &-collapse.show {
        box-shadow: $box-shadow-sm;

        @include media-breakpoint-up(sm) {
          box-shadow: none;
        }
      }

      &-nav {
        min-height: 83px;

        & > .dropdown {

          .nav-link {
            position: relative;
            font-size: 14px;

            &:focus,
            &:hover {
              &::after {
                content: "";
                width: 80%;
                position: absolute;
                bottom: 14px;
                left: 10%;
                border-bottom: solid 2px var(#{--bs-primary});
              }
            }
          }   
          
          & > .nav-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            font-size: 14px;

            @include media-breakpoint-up(lg) {
              justify-content: space-around;
            }

            & > .nav-link {
              white-space: nowrap;
              padding: 16px;
              color: white;
            }

            & > .dropdown-toggle {
              display: flex;
              align-items: center;
              gap: $spacer * 0.5;
              justify-content: center;
              background-color: transparent !important;
              border: 0 !important;
              padding: $spacer $spacer $spacer 0;
              transition: none !important;

              &:after {
                display: none;
              }

              & > svg {
                // stroke: var(#{--bs-secondary}) !important;
                stroke: white;
              }
            }
          }

          & > .dropdown-menu {
            border: none;
            border-radius: 8px;
            margin-top: 0;
            padding: 0;
            overflow: hidden;

            & > .nav-item {
              display: flex;
              align-items: center;
              
              &:active {
                background-color: white;
                color: black;              
              }

              &:hover a {
                text-decoration: underline;
                text-decoration-color: var(--bs-primary);
                text-decoration-thickness: 2px;
                padding-bottom: 12px;
              }

              &:not(:first-child) {
                border-top: 1px solid rgba($white, 0.25);
              }
            }

            .nav-link {
              color: inherit;
              font-size: 14px;
              padding: $spacer * 0.75 $spacer * 1.5;
              white-space: nowrap;
  
              &:focus,
              &:hover {
                &::after {
                  border-bottom: none;
                }
              }
            }
          }
        }

        & > .nav-item {
          display: flex;
          align-items: center;

          & > .nav-link {
            white-space: nowrap;
            padding: 16px;
          }
        }
      }
    }
  }


  background-color: white;
  &.scrolled {
    background-color: white;
    transition: all 300ms ease-in;

    &.announcement {
      padding-top: 0;
    }

    .header__brand {
      padding: $spacer * 0.05;
  
      img {
        height: 60px;
      }
  
      @include media-breakpoint-up(sm) {
        img {
          height: 60px;
        }
      }
    }

    .navItem {
      .navLink {
        color: white;
      }
    }

    :global {
      .navbar {
        &-nav {
          & > .dropdown {
            & > .nav-item {
              color: white;
              & > .nav-link {
                color: white;
              }

              & > .dropdown-toggle {
                & > svg {
                  stroke: var(#{--bs-secondary}) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1350px) {

  .header {

    &__brand {
      img {
        margin-right: 2vw;
      }
  
      @include media-breakpoint-up(sm) {
        padding: 0 !important;
  
        img {
          height: 72px;
        }
      }
    }

    .navButtonList {
      gap: 4px;

      .navButton {
        width: 120px;
        padding: 8px 0;
      }
    }

    :global {
      .navbar {
        &-nav {
          & > .dropdown {
            & > .nav-item {
              & > .nav-link {
                white-space: nowrap;
                padding: 16px 8px;
                color: white;
              }
            }
  
            & > .dropdown-menu {
              .nav-link {
                padding: $spacer * 0.75 $spacer * 1.5;
              }
            }
          }
  
          & > .nav-item {
            & > .nav-link {
              padding: 16px 8px;
            }
          }
        }
      }
    }
  }

}

@media (max-width: 991.98px) {

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    .navButtonList {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;
      padding-bottom: 500px;
  
      a {
        width: 100%;

        .navButton {
          width: 100%;
          padding: 20px 20px;
        }
      }
    }
  }

}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
  .header {
    &__brand {
      img {
        width: 250px;
      }
    }
  }


  .header {

    .navButtonList {
      padding-left: 5vw;
      padding-right: 5vw;
    }

    :global {
      .navbar {
        &-nav {
          padding: 10vh 5vw 5vh 5vw;
        }
      }
    }
  }
}
