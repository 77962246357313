@import "src/scss/module";

.announcement {
  background-color: var(#{--bs-primary});
  color: white;
  padding: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1100;
  transition: all 300ms ease-in;

  &.scrolled {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease-in;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    gap: 4px;

    .icon {
      height: 16px;
      width: 16px;
    }
  }
}

@media (max-width: 1260px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
  .announcement {
    display: flex;
    padding: 5px 12px;

    .body {
      flex: 5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .link {
      flex: 1;
    }
  }
}